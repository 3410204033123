.container {
    overflow-y: auto;
}

.image {
    max-height: 150px;
}

.descrizione {
    max-width: 830px;
    padding-left: 1rem;
}

.table {
    display: grid;
    grid-template-columns: 25% auto 25%;
    column-gap: 2rem;
    word-break: break-all;
    row-gap: 3rem;
}

.input {
    padding: .3rem .6rem;
    border-radius: 10px;
    border: 1px solid black;
    resize: none;
    width: 100%;
}

.textarea {
    max-width: 800px;
}

.details_container {
    padding-left: 5rem;
    padding-right: 5rem;
}

.btns_container {
    display: flex;
    column-gap: 4rem;
    row-gap: 2rem;
}

@media screen and (width < 1150px) {
    .details_container {
        padding-left: 3rem;
        padding-right: 3rem;
    }
}

@media screen and (width < 1050px) {
    .btns_container {
        justify-content: center;
    }

    .table {
        grid-template-columns: 100%;
    }
}

@media screen and (width < 980px) {
    .details_container {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}