.container {
    overflow-y: scroll;
}

.input {
    padding: .3rem .6rem;
    border-radius: 10px;
    border: 1px solid black;
    resize: none;
}

.input_container {
    display: grid;
    grid-template-columns: 20% auto;
    align-items: start;
    max-width: 900px;
    column-gap: .5rem;
}

.pad_horizontal {
    padding-left: 5rem;
    padding-right: 5rem;
}

.buttons_container {
    display: flex;
    row-gap: 2rem;
    column-gap: 4rem;
    margin-top: 3rem;
}

@media screen and (width < 700px) {
    .buttons_container {
        justify-content: center;
        flex-wrap: wrap;
    }
}

@media screen and (width < 600px) {
    .input_container {
        grid-template-columns: 25% auto;
    }
}

@media screen and (width < 640px) {
    .pad_horizontal {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

@media screen and (width < 520px) {
    .input_container {
        grid-template-columns: 100%;
    }

    .input {
        margin-top: .4rem;
    }
}