.container {
    overflow-y: auto;
}

.info_container {
    display: flex;
    align-items: center;
}

.details_container {
    display: grid;
    grid-template-columns: 60% auto;
    column-gap: 2rem;
    padding-left: 5rem;
    padding-right: 5rem;
    row-gap: 3rem;
}

.inputs_container {
    display: grid;
    grid-template-columns: 25% 75%;
    max-width: 600px;
    align-items: start;
    row-gap: 1rem;
}

.input {
    padding: .3rem .6rem;
    border-radius: 10px;
    border: 1px solid black;
    resize: none;
}

.users_list {
    display: grid;
    grid-template-columns: 50% auto;
    column-gap: .8rem;
    row-gap: .5rem;
}

.user_image {
    height: 100%;
    user-select: none;
    -webkit-user-drag: none;
}

.image_container {
    height: 30px;
    width: 30px;
    clip-path: circle(50%);
    display: flex;
    justify-content: center;
    user-select: none;
}

.input_checkbox {
    width: 0;
    height: 0;
}

.input_checkbox::after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: -1rem;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    border: 1px solid black;
    border-radius: 50%;
}

.input_checkbox:checked:after {
    background: var(--primary);
}

.participants_list {
    display: grid;
    row-gap: 1rem;
}

.participant {
    display: grid;
    grid-template-columns: 40% calc(60% - .5rem);
    column-gap: .5rem;
}

.buttons {
    display: flex;
    align-items: center;
    row-gap: 1.5rem;
    flex-wrap: wrap;
    column-gap: 3rem;
}

@media screen and (width < 1160px) {
    .details_container {
        grid-template-columns: 100%;
        padding-left: 3rem;
        padding-right: 3rem;
    }
}

@media screen and (width < 1060px) {
    .buttons {
        justify-content: center;
    }
}

@media screen and (width < 600px) {
    .info_container {
        display: block;
    }

    .buttons_margin {
        margin-left: 0;
    }

    .buttons_top_margin {
        margin-top: 1.5rem;
    }
}

@media screen and (width < 550px) {
    .inputs_container {
        grid-template-columns: 100%;
        row-gap: 0;
    }

    .input_details {
        margin-top: .3rem;
        margin-bottom: 1rem;
    }

    .users_list {
        grid-template-columns: 100%;
        padding-left: 1rem;
    }

    .participant {
        grid-template-columns: 50% 50%;
        column-gap: .5rem;
    }

    .participants_list {
        padding-left: 1rem;
    }

    .details_container {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}