.input {
    width: 100%;
    padding: .5rem 1.5rem;
    border-radius: 10px;
    border: 1px solid black;
    font-size: 1.1rem;
}

.cards_container {
    display: grid;
    column-gap: 5rem;
    row-gap: 5rem;
    grid-template-columns: repeat(auto-fill, 200px);
    justify-content: space-evenly;
    margin-top: 4rem;
    padding-right: 3rem;
    padding-left: 5rem;
}

.card {
    position: relative;
    border-radius: 10px;
    width: 200px;
    overflow: hidden;
}

.card_image {
    max-height: 90px;
}

.card_title_container {
    color: black;
    background: var(--secondary);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.1rem;
    border-radius: 10px 10px 0 0;
}

.no_proprietario .card {
    border: 2px solid var(--primary-unabled);
    box-shadow: 4px 4px 0 0 var(--primary-unabled);
}

.proprietario .card {
    border: 2px solid var(--primary);
    box-shadow: 4px 4px 0 0 var(--primary);
}

.no_proprietario .card_owner_container {
    background: var(--primary-unabled);
    color: black;
}

.proprietario .card_owner_container {
    background: var(--primary);
    color: var(--secondary);
}

.card_owner_container {
    font-size: .8rem;
}

.card_checkbox {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 0;
    width: 0;
}

.card_checkbox::after {
    content: ' ';
    display: block;
    position: absolute;
    top: 50%;
    left: -1.8rem;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border: 2px solid black;
    border-radius: 50%;
    cursor: pointer;
}

.card_checkbox:checked::after {
    background: var(--primary);
}

.container {
    overflow-y: auto;
}

.input_container {
    padding-left: 5rem;
    padding-right: 5rem;
}

.btns_container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 4rem;
    row-gap: 1rem;
}

@media screen and (width < 1100px) {
    .btns_container {
        justify-content: center;
    }
}

@media screen and (width < 560px) {
    .cards_container {
        grid-template-columns: 100%;
        padding-left: 4rem;
    }

    .card {
        width: unset;
    }

    .input_container {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}