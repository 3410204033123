.wrapper {
    display: grid;
    grid-template-rows: 15% 85%;
    height: 100vh;
}

.container {
    display: flex;
    /* padding-top: 130px; */
    height: 100%;
}

@media screen and (width < 860px), screen and (height < 700px) {
    .container {
        flex-direction: column;
    }
    
    .wrapper {
        grid-template-rows: 12% 88%;
    }
}