@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

* {
	--primary-text: #27213C;
	--secondary-text: #FFFFFF;
	--primary: #50514F;
	--primary-dark: #2a2b29;
	--primary-darker: #000000;
	--primary-disabled: #979797;
	--primary-unabled: #D7D7D7;
	--secondary: #FFFFFF;
	--secondary-dark: #ebebeb;
	--secondary-darker: #c9c9c9;
	--tertiary: #D0E3CC;
	--grey: #55535B;
	--deadline: #ecbfbf;
	/* --voted: #69bdee; */
	--voted: #4DA1A9;
	font-family: 'Manrope', sans-serif;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100vh;
	overflow: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

li {
	list-style: none;
}

a {
    -webkit-tap-highlight-color: transparent;
	text-decoration: none;
}

.voted {
	color: var(--voted);
}

.relative {
	position: relative;
}

.block {
	display: block;
}

.flex {
	display: flex;
}

.wrap {
	flex-wrap: wrap;
}

.hidden {
	opacity: 0;
	pointer-events: none;
	user-select: none;
}

.align-center {
	align-items: center;
}

.align-start {
	align-items: start;
}

.align-end {
	align-items: end;
}

.justify-between {
	justify-content: space-between;
}

.justify-evenly {
	justify-content: space-evenly;
}

.justify-center {
	justify-content: center;
}

.grow {
	flex-grow: 1;
}

.overflow-wrap {
	overflow-wrap: break-word;
}

.max-height {
	height: 100%;
}

.max-width {
	width: 100%;
}

.color-primary {
	color: var(--primary-text);
}

.color-secondary {
	color: var(--secondary-text);
}

.bg-primary {
	background: var(--primary)!important;
	color: var(--secondary)!important;
}

.btn {
	border-radius: 10px;
	padding: .3rem 1rem;
}

.btn:hover {
	cursor: pointer;
}

.btn-small {
	padding: .2rem .5rem;
}

.btn-regular {
	padding: .3rem 2rem;
}

.btn-wide {
	padding: .3rem 2rem;
	min-width: 150px;
}

.btn-big {
	padding: .5rem 2rem;
}

.btn-disabled {
	background: var(--primary-disabled);
	border: 1px solid var(--primary-disabled);
	color: var(--secondary);
	pointer-events: none;
	user-select: none;
	cursor: default;
}

.btn-secondary-disabled {
	background: var(--secondary);
	border: 1px solid var(--secondary-darker);
	color: var(--secondary-darker);
	pointer-events: none;
	user-select: none;
	cursor: default;
}

.btn-primary {
	background: var(--primary);
	border: 1px solid var(--primary);
	color: var(--secondary);
}

.btn-primary:hover {
	background: var(--primary-dark);
}

.btn-primary:active {
	background: var(--primary-darker);
}

.btn-secondary {
	background: var(--secondary);
	border: 1px solid var(--primary);
	color: var(--primary);
}

.btn-secondary:hover {
	background: var(--secondary-dark);
}

.btn-secondary:active {
	background: var(--secondary-darker);
}

.alerts-container {
    position: fixed;
    top: 140px;
    left: 0;
    z-index: 10000;
}

.alert {
    max-width: fit-content;
    padding: .5rem 2rem;
    border-radius: 10px;
    transition: opacity .5s ease;
    margin-bottom: 1rem;
}

.alert-warning {
    border: 2px solid #df9a1c;
    background: #f8efc8;
}

.alert-success {
    border: 2px solid #0ba50b;
    background: #c8f8d0;
}

.alert-error {
    border: 2px solid #a50b0b;
    background: #f8c8c8;
}

.alert-fade {
    opacity: 0;
}

.pointer {
	cursor: pointer;
}

.cursor {
	cursor: auto!important;
}

.title {
	text-transform: uppercase;
	font-weight: 700;
	font-size: 1.7rem;
	padding-left: .8rem;
	padding-right: .8rem;
}

.semi-bold {
	font-weight: 600;
}

.text-center {
	text-align: center;
}

.text-start {
	text-align: start;
}

.size-very-small {
	font-size: .6rem;
}

.size-small {
	font-size: .8rem;
}

.size-lighter {
	font-size: .9rem;
}

.size-regular {
	font-size: 1rem;
}

.size-semi-regular {
	font-size: 1.3rem;
}

.size-medium {
	font-size: 1.5rem;
}

.size-big {
	font-size: 2rem;
}

.size-bigger {
	font-size: 3rem;
}

.p-1 {
	padding: 1rem;
}

.p-2 {
	padding: 2rem;
}

.p-3 {
	padding: 3rem;
}

.p-4 {
	padding: 4rem;
}

.p-5 {
	padding: 5rem;
}

.py-0-3 {
	padding-top: .3rem;
	padding-bottom: .3rem;
}

.py-0-5 {
	padding-top: .5rem;
	padding-bottom: .5rem;
}

.py-1 {
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.py-2 {
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.py-3 {
	padding-top: 3rem;
	padding-bottom: 3rem;
}

.py-4 {
	padding-top: 4rem;
	padding-bottom: 4rem;
}

.py-5 {
	padding-top: 5rem;
	padding-bottom: 5rem;
}

.px-0-5 {
	padding-left: .5rem;
	padding-right: .5rem;
}

.px-1 {
	padding-left: 1rem;
	padding-right: 1rem;
}

.px-1-5 {
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}

.px-2 {
	padding-left: 2rem;
	padding-right: 2rem;
}

.px-3 {
	padding-left: 3rem;
	padding-right: 3rem;
}

.px-4 {
	padding-left: 4rem;
	padding-right: 4rem;
}

.px-5 {
	padding-left: 5rem;
	padding-right: 5rem;
}

.pt-1 {
	padding-top: 1rem;
}

.pt-2 {
	padding-top: 2rem;
}

.pt-3 {
	padding-top: 3rem;
}

.pt-4 {
	padding-top: 4rem;
}

.pt-5 {
	padding-top: 5rem;
}

.pr-1 {
	padding-right: 1rem;
}

.pr-2 {
	padding-right: 2rem;
}

.pr-3 {
	padding-right: 3rem;
}

.pr-4 {
	padding-right: 4rem;
}

.pr-5 {
	padding-right: 5rem;
}

.pb-1 {
	padding-bottom: 1rem;
}

.pb-2 {
	padding-bottom: 2rem;
}

.pb-3 {
	padding-bottom: 3rem;
}

.pb-4 {
	padding-bottom: 4rem;
}

.pb-5 {
	padding-bottom: 5rem;
}

.pl-1 {
	padding-left: 1rem;
}

.pl-2 {
	padding-left: 2rem;
}

.pl-3 {
	padding-left: 3rem;
}

.pl-4 {
	padding-left: 4rem;
}

.pl-5 {
	padding-left: 5rem;
}

.m-1 {
	margin: 1rem;
}

.m-2 {
	margin: 2rem;
}

.m-3 {
	margin: 3rem;
}

.m-4 {
	margin: 4rem;
}

.m-5 {
	margin: 5rem;
}

.my-1 {
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.my-2 {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

.my-3 {
	margin-top: 3rem;
	margin-bottom: 3rem;
}

.my-4 {
	margin-top: 4rem;
	margin-bottom: 4rem;
}

.my-5 {
	margin-top: 5rem;
	margin-bottom: 5rem;
}

.mx-auto {
	margin-left: auto;
	margin-right: auto;
}

.mx-1 {
	margin-left: 1rem;
	margin-right: 1rem;
}

.mx-2 {
	margin-left: 2rem;
	margin-right: 2rem;
}

.mx-3 {
	margin-left: 3rem;
	margin-right: 3rem;
}

.mx-4 {
	margin-left: 4rem;
	margin-right: 4rem;
}

.mx-5 {
	margin-left: 5rem;
	margin-right: 5rem;
}

.mt-0-5 {
	margin-top: .5rem;
}

.mt-1 {
	margin-top: 1rem;
}

.mt-2 {
	margin-top: 2rem;
}

.mt-3 {
	margin-top: 3rem;
}

.mt-4 {
	margin-top: 4rem;
}

.mt-5 {
	margin-top: 5rem;
}

.mr-0-5 {
	margin-right: .5rem;
}

.mr-1 {
	margin-right: 1rem;
}

.mr-1-5 {
	margin-right: 1.5rem;
}

.mr-2 {
	margin-right: 2rem;
}

.mr-3 {
	margin-right: 3rem;
}

.mr-4 {
	margin-right: 4rem;
}

.mr-5 {
	margin-right: 5rem;
}

.mb-0-5 {
	margin-bottom: .5rem;
}

.mb-1 {
	margin-bottom: 1rem;
}

.mb-2 {
	margin-bottom: 2rem;
}

.mb-3 {
	margin-bottom: 3rem;
}

.mb-4 {
	margin-bottom: 4rem;
}

.mb-5 {
	margin-bottom: 5rem;
}

.ml-0-5 {
	margin-left: .5rem;
}

.ml-1 {
	margin-left: 1rem;
}

.ml-2 {
	margin-left: 2rem;
}

.ml-3 {
	margin-left: 3rem;
}

.ml-4 {
	margin-left: 4rem;
}

.ml-5 {
	margin-left: 5rem;
}

.ml-7 {
	margin-left: 7rem;
}



@media screen and (width < 1200px) {
	.size-bigger {
		font-size: 2.5rem;
	}
}

@media screen and (width < 850px) {
	.size-bigger {
		font-size: 2rem;
	}
}

@media screen and (width < 600px) {
	.btn-wide {
		min-width: 120px;
	}
}

@media screen and (width < 550px) {
	.size-bigger {
		font-size: 1.8rem;
	}
}