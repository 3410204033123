.container {
    overflow-y: scroll;
}

.wrapper {
    max-width: 600px;
}

.form {
    display: grid;
    grid-template-columns: 30% calc(70% - 1rem);
    column-gap: 1rem;
    row-gap: 2rem;
    align-items: center;
}

.input {
    padding: .3rem .6rem;
    border-radius: 10px;
    border: 1px solid black;
}

.buttons_container {
    display: flex;
    justify-content: space-evenly;
}

.alerts {
    position: fixed;
    top: 140px;
    left: 0;
    z-index: 10000;
}

.alert {
    max-width: fit-content;
    padding: .5rem 2rem;
    border-radius: 10px;
    transition: opacity .5s ease;
    margin-bottom: 1rem;
}

.alert_warning {
    border: 2px solid #df9a1c;
    background: #f8efc8;
}

.alert_success {
    border: 2px solid #0ba50b;
    background: #c8f8d0;
}

.alert_error {
    border: 2px solid #a50b0b;
    background: #f8c8c8;
}

.alert_fade {
    opacity: 0;
}

@media screen and (width < 850px) {
    .wrapper {
        max-width: 450px;
    }
}

@media screen and (width < 700px) {
	.form {
        grid-template-columns: 100%;
        row-gap: .4rem;
	}

    .input {
        margin-bottom: 1.4rem;
    }
}

@media screen and (width < 680px) {
    .wrapper {
        max-width: 380px;
    }
}

@media screen and (width < 600px) {
    .wrapper {
        max-width: 300px;
    }
}