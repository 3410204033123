.container {
    overflow-y: auto;
}

.card {
    position: relative;
    border-radius: 10px;
    border: 2px solid var(--primary);
    box-shadow: 4px 4px 0 0 var(--primary);
    width: 180px;
    overflow: hidden;
}

.cards_container {
    display: grid;
    column-gap: 5rem;
    row-gap: 5rem;
    grid-template-columns: repeat(auto-fill, 180px);
    justify-content: space-evenly;
}

.card_image {
    max-height: 70px;
}

.card_title_container {
    color: black;
    background: var(--secondary);
    font-weight: 600;
    word-wrap: break-word;
}

.download_container {
    background: var(--primary);
    cursor: pointer;
    color: var(--secondary-text);
    user-select: none;
}

.card_checkbox {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 0;
    width: 0;
}

.card_checkbox::after {
    content: ' ';
    display: block;
    position: absolute;
    top: 50%;
    left: -1.8rem;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border: 2px solid black;
    border-radius: 50%;
    cursor: pointer;
}

.card_checkbox:checked::after {
    background: var(--primary);
}

.input {
    width: 100%;
    border: none;
    outline: none;
    font-weight: 600;
    font-size: 1rem;
    padding: .3rem .7rem;
}

.file_input {
    width: 0;
    height: 0;
    display: block;
}

.buttons_container {
    display: flex;
    column-gap: 4rem;
    row-gap: 2rem;
}

@media screen and (width < 1460px){
    .form_container {
        padding: 4rem 1.5rem;
    }
}

@media screen and (width < 960px) {
    .cards_container {
        grid-template-columns: 100%;
        padding: 0 2rem;
    }

    .card {
        width: unset;
    }

    .buttons_container {
        justify-content: center;
        flex-wrap: wrap;
    }
}

@media screen and (width < 860px) {
    .cards_container {
        grid-template-columns: repeat(auto-fill, 180px);
        padding: 0;
    }

    .card {
        width: 180px;
    }
}

@media screen and (width < 750px) {
    .cards_container {
        grid-template-columns: 100%;
    }

    .card {
        width: unset;
    }
}