.container {
    overflow-y: auto;
}

.pad_horizontal {
    padding-left: 5rem;
    padding-right: 5rem;
}

.description_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 2rem;
    row-gap: 2rem;
}

.cards_container {
    display: grid;
    grid-template-columns: repeat(auto-fill, 180px);
    column-gap: 5rem;
    row-gap: 3rem;
    justify-content: space-evenly;
    padding-left: 5rem;
    padding-right: 5rem;
}

.card {
    width: 180px;
    border-radius: 10px;
    border: 1px solid var(--primary);
}

.input {
    border: none;
    resize: none;
    background: none;
    outline: none;
    color: var(--secondary);
}

.input::placeholder {
    color: #D3D3D3;
}

.textarea {
    color: black;
}

.textarea::placeholder {
    color: var(--grey);
}

.card_title {
    background: var(--primary);
    color: var(--secondary);
    padding: .5rem 1rem;
    border-radius: 8px 8px 0 0;
}

.card_votes {
    border-top: 1px solid var(--primary);
    padding: .5rem 0;
}

.card_content {
    padding: .8rem;
    overflow-wrap: anywhere;
}

.card_label {
    position: absolute;
    top: -2.3rem;
    left: 0;
}

.class {
    padding-left: 5rem;
}

.buttons {
    border: none;
    background: none;
}

@media screen and (width < 1360px) {
    .descrizione {
        padding-left: 4rem;
        padding-right: 2rem;
    }
}

@media screen and (width < 1100px) {
    .wrapper {
        grid-template-columns: 100%;
        row-gap: 2rem;
    }

    .header_container {
        grid-template-columns: 75% auto;
    }

    .descrizione {
        padding-left: 2rem;
        padding-right: 1rem;
    }
}

@media screen and (width < 990px) {
    .description_container {
        flex-wrap: wrap;
        justify-content: center;
    }
}

@media screen and (width < 860px) {
    .description_container {
        flex-wrap: nowrap;
        justify-content: space-between;
    }
}

@media screen and (width < 800px) {
    .pad_horizontal {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .description_container {
        flex-wrap: wrap;
        justify-content: center;
    }

    .class {
        padding-left: 0;
        text-align: center;
    }
}

@media screen and (width < 650px) {
    .header_container {
        grid-template-columns: 100%;
        row-gap: 2rem;
    }

    .status_container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        column-gap: .8rem;
        row-gap: 1rem;
        flex-wrap: wrap;
    }

    .categoria {
        margin-top: 0;
    }
}