.header {
    background: #FF7B8B;
    padding: 2rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    /* position: fixed; */
    width: 100%;
    z-index: 1000;
    /* height: 130px; */
    display: flex;
    align-items: center;
}

.img_container {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
    margin-left: 2rem;
}

.img_container img {
    width: 70px;
    height: 70px;
}

@media screen and (width < 560px) {
    .img_container {
        width: 50px;
        height: 50px;
        margin-left: 1rem;
    }
    
    .img_container img {
        width: 50px;
        height: 50px;
    }
}