.container {
    overflow-y: auto;
    padding-left: 3rem;
    padding-right: 3rem;
}

.list_element {
    list-style: disc;
}

.btns_container {
    display: flex;
    column-gap: 1rem;
}