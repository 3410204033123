.container {
    overflow-y: auto;
    padding: 4rem 4rem 4rem 9rem;
}

.user_image {
    height: 100%;
    user-select: none;
    -webkit-user-drag: none;
}

.image_container {
    height: 80px;
    width: 80px;
    clip-path: circle(50%);
    display: flex;
    justify-content: center;
    user-select: none;
}

.info_container {
    display: flex;
    align-items: center;
}

.stato_inchiesta {
    margin-left: 7rem;
}

.dati_container {
    display: grid;
    grid-template-columns: 40% auto;
    column-gap: 1rem;
    max-width: 700px;
    row-gap: 1rem;
    align-items: center;
}

.dati_input_container {
    display: grid;
    grid-template-columns: 40% auto;
    column-gap: 1rem;
    max-width: 700px;
    row-gap: 1rem;
    align-items: center;
}

.input {
    padding: .3rem .6rem;
    border-radius: 10px;
    border: 1px solid black;
}

.file_input {
    width: 0;
    height: 0;
    display: none;
}

.checkbox {
    width: 0;
    height: 0;
}

.checkbox::after {
    content: ' ';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 15px;
    height: 15px;
    border: 2px solid black;
    border-radius: 50%;
}

.checkbox:checked:after {
    background: var(--primary);
}

.logout {
    margin-left: 4rem;
}

.wapper {
    padding-left: 5rem;
}

@media screen and (width < 1220px) {
    .container {
        padding: 4rem 4rem 4rem 5rem;
    }
}

@media screen and (width < 1050px) {
    .info_container {
        display: block;
    }

    .stato_inchiesta {
        margin-left: 0;
    }

    .logout {
        margin-left: 0;
    }

    .container {
        padding: 4rem 2rem;
    }

    .stato_container {
        margin-top: 2rem;
    }
}

@media screen and (width < 860px) {
    .wapper {
        padding-left: 3rem;
    }

    .container {
        padding: 2rem 2rem 3rem;
    }
}


@media screen and (width < 600px) {
    .dati_input_container {
        grid-template-columns: auto;
        row-gap: .5rem;
    }
    
    .input {
        margin-bottom: 1rem;
    }

    .checkbox_container {
        margin-bottom: 1rem;
    }
}