.cards_container {
    display: grid;
    column-gap: 5rem;
    row-gap: 5rem;
    grid-template-columns: repeat(auto-fill, 180px);
    justify-content: space-evenly;
}

.pad_horizontal {
    padding-left: 5rem;
    padding-right: 5rem;
}

.card {
    position: relative;
    border-radius: 10px;
    box-shadow: 4px 4px 0 0 var(--primary);
    cursor: pointer;
    width: 180px;
    border: 2px solid var(--primary);
    overflow: hidden;
}

.card_image {
    max-height: 90px;
    margin: auto;
    display: block;
}

.card_checkbox {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 0;
    width: 0;
}

.card_checkbox::after {
    content: ' ';
    display: block;
    position: absolute;
    top: 50%;
    left: -1.8rem;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border: 2px solid black;
    border-radius: 50%;
    cursor: pointer;
}

.card_checkbox:checked::after {
    background: var(--primary);
}

.card_title_container {
    color: var(--secondary);
    background: var(--primary);
    font-weight: 600;
    padding: .7rem;
}

.card_number_container {
    color: black;
    background: var(--secondary);
    border-radius: 0 0 10px 10px;
    border-top: 1px solid var(--primary);
}

.wrapper {
    overflow-y: auto;
    padding-bottom: 3rem;
}

.buttons_container {
    display: flex;
    column-gap: 4rem;
    row-gap: 1rem;
}

@media screen and (width < 1160px) {
    .buttons_container {
        justify-content: center;
    }
}

@media screen and (width < 1060px) {
    .pad_horizontal {
        padding-left: 3rem;
        padding-right: 3rem;
    }
}

@media screen and (width < 800px) {
    .buttons_container {
        flex-wrap: wrap;
    }
}

@media screen and (width < 560px) {
    .cards_container {
        grid-template-columns: 100%;
    }

    .card {
        width: unset;
    }
}