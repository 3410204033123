.wrapper {
    display: grid;
    grid-template-columns: 70% 30%;
}

.container {
    overflow-y: auto;
}

.description_container {
    justify-content: space-between;
    padding-left: 5rem;
    padding-right: 5rem;
    row-gap: 2rem;
    column-gap: 4rem;
}

.buttons_container {
    padding-left: 5rem;
    padding-right: 5rem;
    row-gap: 4rem;
}

.actions_buttons {
    display: flex;
    row-gap: 1.5rem;
    column-gap: 1rem;
}

.grid_container {
    display: flex;
    flex-wrap: wrap;
    row-gap: 3rem;
    column-gap: 5rem;
    align-items: start;
    justify-content: space-evenly;
    padding-left: 5rem;
}

.list_container {
    padding-left: 5rem;
}

.card {
    position: relative;
    border-radius: 10px;
    border: 1px solid var(--primary);
    box-shadow: 4px 4px 0 0 var(--primary);
    width: 180px;
    overflow: hidden;
}

.card_image {
    max-height: 70px;
}

.card_file_image {
    max-width: 100%;
    max-height: 350px;
}

.card_title_container {
    font-weight: 600;
    word-wrap: break-word;
    padding: .4rem 2rem;
    text-align: center;
}

.card_download_container {
    background: var(--primary);
    cursor: pointer;
    color: var(--secondary-text);
}

.card_link {
    background: var(--secondary);
    border: 1px solid var(--primary);
    padding: .4rem 2rem;
    border-radius: 10px;
    user-select: none;
    max-width: 295px;
    word-wrap: break-word;
    word-break: break-all;
    box-shadow: 4px 4px 0 0 var(--primary);
}

.chat_container {
    padding-right: 5rem;
}

.user_image {
    height: 100%;
    user-select: none;
    -webkit-user-drag: none;
}

.image_container {
    height: 45px;
    width: 45px;
    clip-path: circle(50%);
    display: flex;
    justify-content: center;
    user-select: none;
}

.container_commenti {
    max-height: 500px;
    overflow-y: auto;
}

.input {
    padding: .3rem .6rem;
    border-radius: 10px;
    border: 1px solid black;
}

.stato_inchiesta {
    margin-left: 7rem;
}

.content_container {
    padding-right: 3rem;
}

.descrizione {
    color: var(--grey);
}

.input_link_container {
    display: grid;
    grid-template-columns: 15% calc(65% - .5rem) calc(20% - 1rem);
    column-gap: .5rem;
    max-width: 550px;
    margin-top: 1rem;
    align-items: center;
}

.input_file_container {
    display: grid;
    grid-template-columns: 15% calc(85% - .5rem);
    column-gap: .5rem;
    max-width: 550px;
    margin-top: 1rem;
    align-items: center;
}

.input_risorsa {
    display: grid;
    grid-template-columns: 30% auto;
    max-width: 550px;
    margin-bottom: 2.5rem;
    align-items: center;
}

.file_input {
    width: 0;
    height: 0;
    display: none;
}

.file_list_element {
    display: grid;
    grid-template-columns: 80% auto;
    margin-top: 1rem;
    border: 1px solid var(--primary);
    border-radius: 10px;
    overflow: hidden;
}

.list_download_container {
    background: var(--primary);
    cursor: pointer;
    color: var(--secondary-text);
    user-select: none;
}

.list_title_container {
    font-weight: 600;
    word-wrap: break-word;
    padding: .4rem 2rem;
    word-break: break-all;
}

.list_link {
    border: 1px solid var(--primary);
    padding: .4rem 2rem;
    border-radius: 10px;
    user-select: none;
    word-wrap: break-word;
    margin-top: 1rem;
    word-break: break-all;
}

.card_link > a, .list_link > a {
    color: black;
}

.checkbox {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 0;
    width: 0;
}

.checkbox::after {
    content: ' ';
    display: block;
    position: absolute;
    top: 50%;
    left: -1.8rem;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border: 2px solid black;
    border-radius: 50%;
    cursor: pointer;
}

.checkbox:checked::after {
    background: var(--primary);
}

.form_container {
    padding-left: 5rem;
    padding-right: 5rem;
}

@media screen and (width < 1160px) {
    .wrapper {
        grid-template-columns: 100%;
    }

    .stato_inchiesta {
        margin-left: 5rem;
    }

    .content_container {
        padding-right: 0;
    }

    .grid_container {
        padding-left: 3rem;
        padding-right: 2rem;
    }

    .chat_container {
        margin-top: 4rem;
        padding-left: 5rem;
    }

    .list_container {
        padding-right: 3rem;
    }
}

@media screen and (width < 1050px) {
    .description_container {
        justify-content: center;
        flex-direction: column;
        padding-left: 3rem;
        padding-right: 3rem;
    }
}

@media screen and (width < 990px) {
    .stato_inchiesta {
        margin-left: 3rem;
    }

    .checkbox::after {
        left: -2rem;
    }

    .chat_container {
        padding-left: 3rem;
        padding-right: 3rem;
    }
}

@media screen and (width < 950px) {
    .buttons_container {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .form_container {
        padding-left: 3rem;
        padding-right: 3rem;
    }
}

@media screen and (width < 600px) {
    .info_container {
        flex-direction: column;
        row-gap: 2rem;
    }

    .descrizione {
        padding: 0 1rem;
    }

    .input_link_container {
        grid-template-columns: 15% calc(55% - .5rem) calc(30% - 1rem);
    }

    .description_container {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .actions_buttons {
        flex-direction: column;
    }

    .chat_container {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .list_container {
        padding-left: 3rem;
        padding-right: 1.5rem;
    }
}

@media screen and (width < 540px) {
    .list_element {
        width: 100%;
    }

    .card {
        width: unset;
    }

    .card_link {
        max-width: unset;
    }

    .file_list_element {
        grid-template-columns: 60% auto;
    }

    .form_container {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}