.container {
    background: var(--primary);
    min-width: 250px;
    padding: 2rem;
}

.title {
    text-transform: uppercase;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.title::after {
    transition: all .3s ease;
}

.link > a {
    color: var(--secondary-text);
    text-decoration: none;
    display: block;
}

.collapse {
    margin-top: 1rem;
    transition: all .2s ease-out;
}

.max_height {
    max-height: unset;
}

.badge {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-20%) translateX(60%);
    font-size: 12px;
    aspect-ratio: 1;
    width: 17px;
    text-align: center;
    border-radius: 50%;
    background: #ffffff;
    color: var(--primary);
    font-weight: bold;
}

@media screen and (width < 860px), screen and (height < 700px) {
    .container {
        padding: 0 2.5rem;
    }

    .title {
        text-transform: uppercase;
        margin-top: .8rem;
        margin-bottom: .8rem;
        position: relative;
        cursor: pointer;
    }

    .title::after {
        position: absolute;
        top: 50%;
        left: 80%;
        transform: translateY(-80%) rotateZ(45deg);
        content: ' ';
        background: none;
        border-bottom: 3px solid var(--secondary);
        border-right: 3px solid var(--secondary);
        width: 10px;
        height: 10px;
    }

    .rotated.title::after {
        transform: translateY(-40%) rotateZ(45deg) rotateX(180deg) rotateY(180deg);
    }

    .collapse {
        overflow: hidden;
    }

    .max_height {
        max-height: 0;
    }

    .list_container {
        padding-bottom: 2rem;
        padding-top: 2rem;
        display: grid;
        grid-template-columns: 49% calc(51% - 1rem);
        column-gap: 1rem;
    }
}