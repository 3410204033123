.container {
    overflow-y: scroll;
}

.current_month {
    max-width: 900px;
}

.calendar_container {
    max-width: fit-content;
    position: relative;
}

.new_btn {
    position: absolute;
    right: -1rem;
    bottom: 1rem;
    transform: translateX(100%);
    aspect-ratio: 1;
    background: var(--secondary);
    color: var(--primary);
    border: 2px solid var(--primary);
    box-shadow: 2px 2px 0 0 var(--primary);
    font-size: 1.2rem;
    padding: 0 .7rem;
}

.week {
    max-width: fit-content;
    display: grid;
    grid-template-columns: repeat(7, 120px);
}

.day {
    width: 120px;
    height: 120px;
    padding: .5rem;
    overflow: hidden;
}

.regular_border {
    border: 1px solid #3d3d3d;
}

.border_double_top {
    border-top: 2px solid #3d3d3d;
}

.border_double_bottom {
    border-bottom: 2px solid #3d3d3d;
}

.border_double_left {
    border-left: 2px solid #3d3d3d;
}

.border_double_right {
    border-right: 2px solid #3d3d3d;
}

.border_grey_color {
    border-color: #f0f0f0;
}

.grey_color {
    color: #cacaca;
}

.evento {
    font-size: .7rem;
    background-color: var(--tertiary);
    border-radius: 5px;
    white-space: nowrap;
    cursor: default;
    overflow: hidden;
}

.scadenza {
    background-color: var(--deadline);
}

.input {
    display: block;
    width: 100%;
    padding: .3rem .6rem;
    border-radius: 10px;
    border: 1px solid black;
    margin-bottom: 2rem;
}

.form_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: var(--secondary);
    padding: 2rem 3rem;
    border: 2px solid var(--primary);
    border-radius: 10px;
    box-shadow: 4px 4px 0 0 var(--primary);
    min-width: 410px;
    overflow: hidden;
}

.form_container.hide {
    /* width: 0;
    height: 0;
    min-width: 0;
    opacity: 0;
    left: calc(100% + 1rem);
    top: calc(100% - 1rem);
    transform: translateX(100%);
    padding: 0; */
    display: none;
}

.form_title {
    font-size: 1.5rem;
}

.close_btn {
    position: absolute;
    right: 0;
    top: 0;
    aspect-ratio: 1;
    background: var(--secondary);
    color: var(--primary);
    border: 2px solid var(--primary);
    box-shadow: 2px 2px 0 0 var(--primary);
    font-size: 1.2rem;
    padding: 0 .7rem;
}

.add_btn {
    margin-top: 3rem;
}

.input_container {
    display: flex;
    column-gap: 2rem;
}

.arrow_buttons {
    cursor: pointer;
    background: none;
    border: none;
}

.checkbox {
    height: 0;
    width: 0;
}

.checkbox::after {
    content: ' ';
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 15px;
    height: 15px;
    border: 2px solid black;
    border-radius: 50%;
    cursor: pointer;
}

.checkbox:checked::after {
    background: var(--primary);
}

@media screen and (width < 1660px) {
    .week {
        grid-template-columns: repeat(7, 110px);
    }
    
    .day {
        width: 110px;
        height: 110px;
    }
}

@media screen and (width < 1450px) {
    .container {
        display: block;
    }

    .week {
        grid-template-columns: repeat(7, 90px);
    }
    
    .day {
        width: 90px;
        height: 90px;
        padding: .4rem;
    }
}

@media screen and (width < 1180px) {
    .evento {
        max-height: 1rem;
        overflow: hidden;
    }
}

@media screen and (width < 1150px) {
    .new_btn {
        left: calc(100% - .5rem);
        bottom: -1.5rem;
        transform: translateX(-100%);
        position: relative;
        right: unset;
    }
}

@media screen and (width < 1000px) {
    .week {
        grid-template-columns: repeat(7, 80px);
    }
    
    .day {
        width: 80px;
        height: 80px;
    }
}

@media screen and (width < 930px) {
    .week {
        grid-template-columns: repeat(7, 70px);
    }
    
    .day {
        width: 70px;
        height: 70px;
    }
}

@media screen and (width < 860px) {
    .week {
        grid-template-columns: repeat(7, 90px);
    }
    
    .day {
        width: 90px;
        height: 90px;
    }
}

@media screen and (width < 760px) {
    .week {
        grid-template-columns: repeat(7, 70px);
    }
    
    .day {
        width: 70px;
        height: 70px;
    }

    .input_container {
        flex-wrap: wrap;
    }

    .input_container > * {
        flex-grow: 1;
    }

    .form_container {
        width: 100%;
        min-width: unset;
        padding: 1.5rem 2rem;
    }
}

@media screen and (width < 600px) {
    .week {
        grid-template-columns: repeat(7, 60px);
        font-size: .8rem;
    }
    
    .day {
        width: 60px;
        height: 60px;
    }

    .evento {
        font-size: .6rem;
        padding: 0 .1rem;
    }
}

@media screen and (width < 520px) {
    .week {
        grid-template-columns: repeat(7, 50px);
        font-size: .7rem;
    }
    
    .day {
        width: 50px;
        height: 50px;
        padding: .3rem .2rem;
    }

    .evento {
        font-size: .5rem;
        border-radius: 2px;
    }

    .new_btn {
        font-size: 1rem;
        padding: 0 .5rem;
    }

    .input {
        margin-bottom: 1.5rem;
    }

    .add_btn {
        margin-top: 1.5rem;
    }

    .close_btn {
        font-size: 1rem;
        padding: 0 .5rem;
    }

    .form_title {
        font-size: 1.2rem;
    }
}