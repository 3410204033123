.container {
    overflow-y: auto;
}

.description_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 4rem;
    column-gap: 4rem;
    row-gap: 3rem;
}

.descrizione {
    padding-left: 2rem;
}

.table {
    display: grid;
    grid-template-columns: 25% auto 25%;
    column-gap: 2rem;
    row-gap: 3rem;
}

.table_bodys > a{
    color: black;
    word-break: break-all;
}

.nota {
    list-style: disc;
    margin-bottom: .2rem;
}

.input {
    padding: .3rem .6rem;
    border-radius: 10px;
    border: 1px solid black;
    resize: none;
    max-width: 390px;
}

.details_container {
    padding-left: 5rem;
    padding-right: 5rem;
}

@media screen and (width < 1350px) {
    .description_container {
        padding-right: 0;
    }
}

@media screen and (width < 1050px) {
    .table {
        grid-template-columns: 100%;
    }
}

@media screen and (width < 980px) {
    .details_container {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .image {
        max-height: 120px;
    }
}

@media screen and (width < 650px) {
    .description_container {
        flex-direction: column;
    }
}