.container {
    overflow-y: auto;
}

.list_element {
    display: grid;
    grid-template-columns: 50px calc(35% - .5rem) calc(30% - 1rem) auto;
    column-gap: 1rem;
    row-gap: 1rem;
    max-width: 600px;
    border-radius: 10px;
    border: 2px solid var(--primary);
    padding: .3rem 1rem;
    box-shadow: 4px 4px 0 0 var(--primary);
    width: 100%;
}

.list_element:first-of-type {
    grid-template-columns: 50px calc(35% - .5rem) auto;
}

.user_image {
    height: 100%;
    user-select: none;
    -webkit-user-drag: none;
}

.new_accounts_btn {
    width: 100%;
    height: 100%;
    max-width: 600px;
}

.image_container {
    height: 50px;
    width: 50px;
    clip-path: circle(50%);
    display: flex;
    justify-content: center;
    user-select: none;
}

.input {
    width: 100%;
    padding: .2rem .4rem;
    border-radius: 10px;
    border: 1px solid black;
}

.list_container {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 5rem;
    row-gap: 2rem;
    padding-left: 5rem;
    padding-right: 5rem;
}

@media screen and (width < 1450px) {
    .list_container {
        column-gap: 3rem;
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .list_element {
        grid-template-columns: 50px calc(30% - .5rem) calc(30% - 1rem) auto;
    }
}

@media screen and (width < 1250px) {
    .list_container {
        grid-template-columns: auto;
        justify-items: center;
    }
}

@media screen and (width < 650px) {
    .accounts_container {
        padding: 2rem 0;
    }
}

@media screen and (width < 550px) {
    .grid_span_rows {
        grid-row: 1 / span 2;
    }

    .list_element {
        grid-template-columns: 50px calc(30% - .5rem) auto;
        padding: .8rem 1rem;
    }

    .list_container {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}