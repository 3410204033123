.wrapper {
    overflow-y: auto;
}

.container {
    max-width: 1000px;
    margin: auto;
    padding-left: 3rem;
    padding-right: 3rem;
}

.nuove_notifiche {
    background: var(--primary);
    color: var(--secondary);
    padding: .5rem 2rem;
    border-radius: 8px;
    user-select: none;
    margin-bottom: 1.5rem;
}

.nuove_notifiche:last-of-type {
    margin-bottom: 0;
}

.vecchie_notifiche {
    background: var(--secondary);
    color: var(--primary);
    border: 1px solid var(--primary);
    padding: .5rem 2rem;
    border-radius: 8px;
    user-select: none;
    margin-bottom: 1.5rem;
}

.vecchie_notifiche:last-of-type {
    margin-bottom: 0;
}